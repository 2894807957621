import {IMembersAreaWidgetPluginService} from '@wix/members-area-widget-plugin-lib/viewer'
import {createEventHandler} from '@wix/tpa-settings'
import {ControllerParams} from '@wix/yoshi-flow-editor'
import {fetchInitialState} from './controller-utils'

interface RegisterWidgetPluginHostListenersParams {
  widgetPluginService: IMembersAreaWidgetPluginService
  controllerParams: ControllerParams
  componentEventHandler: ReturnType<typeof createEventHandler>
}

export const registerWidgetPluginHostListeners = ({
  widgetPluginService,
  controllerParams,
  componentEventHandler,
}: RegisterWidgetPluginHostListenersParams) => {
  widgetPluginService.onMembersAreaEvent(event => {
    switch (event.type) {
      case 'MEMBERS_CHANGED':
        return void fetchInitialState({
          controllerParams,
          componentEventHandler,
        })
    }
  })
}
