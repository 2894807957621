import {getEventId, isTicketed} from '@wix/wix-events-commons-statics'
import {DetailedEvent} from '../types/state'

export const isExpanded = (expandedEvents: string[], event: DetailedEvent): boolean =>
  expandedEvents.includes(getEventId(event.event))

export const hasOrder = (event: DetailedEvent): boolean => event.orders.length > 0

export const getEventOrders = (event: DetailedEvent): wix.events.ticketing.Order[] => event.orders

export const getEventRsvp = (event: DetailedEvent): wix.events.rsvp.Rsvp => event.rsvp

export const isTicketedWithNoOrder = (event: DetailedEvent) => !hasOrder(event) && isTicketed(event.event)
