import {Dispatch} from 'redux'
import {Routes} from '../../../../commons/enums'
import {isDetailsPageEnabled} from '../../../../commons/selectors/site-settings'
import * as navigation from '../../../../commons/services/navigation'
import {getNoEventsPage} from '../selectors/component'
import {getNoUpcomingEventsPageId} from '../selectors/navigation'
import {DetailedEvent, GetState, RouteParams, StoreExtraArgs} from '../types/state'

export const UPDATE_NO_EVENT_URL = 'UPDATE_NO_EVENT_URL'
export const INTERNAL_NAVIGATE = 'INTERNAL_NAVIGATE'
export const NAVIGATE_TO_DETAILS_PAGE = 'NAVIGATE_TO_DETAILS_PAGE'

export const navigateToDetailsPage =
  (event: DetailedEvent) =>
  async (dispatch: Dispatch, getState: GetState, {wixCodeApi}: StoreExtraArgs) => {
    const state = getState()
    dispatch(navigateToDetailsPageClicked())
    await navigation.navigateToDetailsPage(event.event, isDetailsPageEnabled(state.siteSettings), wixCodeApi)
  }

export const updateNoEventsUrl =
  () =>
  async (dispatch: Function, getState: GetState, {wixCodeApi, flowAPI}: StoreExtraArgs) => {
    const page = (await getNoEventsPage({wixCodeApi, flowAPI, state: getState()})) as any
    const url = `${wixCodeApi.location.baseUrl}${page.prefix ? `/${page.prefix}` : ''}${page.url}`

    return dispatch({
      type: UPDATE_NO_EVENT_URL,
      payload: {
        url,
        pageId: page.id,
      },
    })
  }

export const navigateToDetailsPageClicked = () => ({type: NAVIGATE_TO_DETAILS_PAGE})

export const internalNavigate = (route: Routes, params: RouteParams = {}) => ({
  type: INTERNAL_NAVIGATE,
  payload: {route, params},
})

export const navigateToNoUpcomingEventsUrl =
  () =>
  (_dispatch: Function, getState: GetState, {wixCodeApi}: StoreExtraArgs) =>
    wixCodeApi.location.navigateTo({pageId: getNoUpcomingEventsPageId(getState())})
