import {SiteSettings} from '@wix/ambassador-wix-events-web/types'
import {EventsHttpClient} from '@wix/wix-events-commons-statics'

export interface GetComponentDataParams {
  responsive?: boolean
  locale: string
  language: string
  compId: string
  viewMode: string
}

export interface GetComponentDataResponse {
  siteSettings: SiteSettings
}

export const getComponentData =
  (api: EventsHttpClient) =>
  async ({responsive, locale, language, compId, viewMode}: GetComponentDataParams): Promise<GetComponentDataResponse> =>
    api.get(
      `/html/members-page-data?viewMode=${viewMode}&locale=${language}&compId=${compId}&responsive=${responsive}&regional=${
        locale || ''
      }&noComponent=true`,
    )
