import {callAPI, createActions} from '../../../../commons/actions/data-action-helper'
import {getMemberId} from '../selectors/user'
import {GetState} from '../types/state'

export const DOWNLOAD_TICKETS_ACTION = 'DOWNLOAD_TICKETS_ACTION'
export const GET_MY_TICKETS = createActions('GET_MY_TICKETS')

export const getMyTickets = (eventId: string) => (dispatch: Function, getState: GetState) =>
  dispatch(callAPI(GET_MY_TICKETS, eventId, getMemberId(getState())))

export const downloadTicketsAction = (eventId: string) => ({
  type: DOWNLOAD_TICKETS_ACTION,
  payload: {
    eventId,
  },
})
