import {AnyAction} from 'redux'
import {getEvents} from '../actions/events'

const defaultState: boolean = false

export const loading = (state = defaultState, action: AnyAction): boolean => {
  switch (action.type) {
    case getEvents.pending.toString():
      return true
    case getEvents.fulfilled.toString():
    case getEvents.rejected.toString():
      return false
    default:
      return state
  }
}
