import {BI_ORIGINS, PAST_EVENT_STATUSES, UPCOMING_EVENT_STATUSES, getEventId} from '@wix/wix-events-commons-statics'
import {setActiveElement} from '../../../../commons/actions/focus-handler'
import {EventsList, Tabs} from '../../../../commons/enums'
import {getLocale} from '../../../../commons/selectors/environment'
import {getEventUrl} from '../../../../commons/selectors/event'
import {openModal} from '../../../../commons/services/modal'
import {isTicketedWithNoOrder} from '../selectors/event'
import {getMemberId, isOwnProfile} from '../selectors/user'
import {createAsyncAction} from '../services/redux-toolkit'
import {DetailedEvent, GetState, StoreExtraArgs} from '../types/state'
import {getMyTickets} from './tickets'

export const TOGGLE_EVENT_DETAILS = 'TOGGLE_EVENT_DETAILS'
export const CLOSE_ALL_EVENTS = 'CLOSE_ALL_EVENTS'
export const SET_TAB = 'SET_TAB'
export const SEE_OTHER_EVENTS = 'SEE_OTHER_EVENTS'
export const SHARE_EVENT_CLICKED = 'SHARE_EVENT_CLICKED'

export const getEvents = createAsyncAction<{events: wix.events.Event[]}, {tab: Tabs}>(
  'GET_EVENTS',
  async ({tab}, {getState, extra: {serverApi}}) => {
    const state = getState()
    const isUpcoming = tab === Tabs.UPCOMING
    const statuses = isUpcoming ? UPCOMING_EVENT_STATUSES : PAST_EVENT_STATUSES
    const offset = state.events[isUpcoming ? EventsList.UPCOMING : EventsList.PAST].length
    const memberId = getMemberId(state)
    const locale = getLocale(state)

    return serverApi.getEvents({
      memberId,
      statuses,
      extended: isOwnProfile(state),
      offset,
      locale,
    })
  },
)

export const toggleEventDetails = (event: DetailedEvent) => async (dispatch: Function, getState: GetState) => {
  const eventId = getEventId(event.event)
  const state = getState()

  if (state.user.currentUserId === state.user.viewedSiteMemberId && isTicketedWithNoOrder(event)) {
    dispatch(getMyTickets(eventId))
  }

  dispatch({
    type: TOGGLE_EVENT_DETAILS,
    payload: eventId,
  })
}

export const closeAllEvents = () => ({
  type: CLOSE_ALL_EVENTS,
})

export const setTab = (tab: Tabs) => ({
  type: SET_TAB,
  payload: tab,
})

interface ShareEventArgs {
  event: DetailedEvent
  returnFocusElement?: string
}
export const shareEvent =
  ({event: {event}, returnFocusElement}: ShareEventArgs) =>
  async (dispatch: Function, getState: GetState, {pageUrl}: StoreExtraArgs) => {
    const eventId = event.id
    const eventUrl = getEventUrl(pageUrl, event, getState().multilingual)
    dispatch(shareEventClicked())
    await dispatch(
      openModal({
        type: 'share-event',
        params: {eventUrl, eventId, origin: BI_ORIGINS.MEMBERS_AREA},
      }),
    )
    dispatch(setActiveElement(returnFocusElement))
  }

export const seeOtherEvents = () => ({type: SEE_OTHER_EVENTS})

export const shareEventClicked = () => ({type: SHARE_EVENT_CLICKED})
