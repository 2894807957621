import {AnyAction} from 'redux'
import {GET_MY_TICKETS} from '../actions/tickets'

const defaultState = {}

export const tickets = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case GET_MY_TICKETS.SUCCESS:
      return {
        ...state,
        [action.args[0]]: action.payload.tickets,
      }
    default:
      return state
  }
}
