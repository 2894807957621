import {createReducer} from '@reduxjs/toolkit'
import {setActiveElement} from '../actions/focus-handler'

const initialState: FocusHandlerState = {
  activeElement: null,
}
export const focusHandler = createReducer(initialState, builder => {
  builder.addCase(setActiveElement, (state, action) => ({...state, activeElement: action.payload}))
})

export interface FocusHandlerState {
  activeElement: string
}
