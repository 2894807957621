import {createReducer} from '@reduxjs/toolkit'
import {setToast} from '../actions/toast-handler'
import {Toast} from '../components/toast-handler/interfaces'

export interface ToastHandlerState {
  toast: Toast
}

const initialState: ToastHandlerState = {
  toast: null,
}

export const toastHandler = createReducer(initialState, builder => {
  builder.addCase(setToast, (state, action) => ({...state, toast: action.payload}))
})
