import {AnyAction} from 'redux'
import {UPDATE_SITE_SETTINGS, setSiteSettings} from '../actions/site-settings'
import {SiteSettings} from '../types/state'

const defaultState: SiteSettings = {} as SiteSettings

export const siteSettings = (state = defaultState, action: AnyAction): SiteSettings => {
  switch (action.type) {
    case setSiteSettings.toString():
    case UPDATE_SITE_SETTINGS.SUCCESS:
      return action.payload.settings
    default:
      return state
  }
}
