import {AnyAction} from 'redux'
import {RESET_TO_LIVE_VIEW, SHOW_DEMO_EVENTS, SHOW_NO_EVENTS} from '../actions/view'
import {View} from '../types/state'

const defaultState: View = {
  demo: false,
  noEvents: false,
}

export const viewReducer = (state = defaultState, action: AnyAction): View => {
  switch (action.type) {
    case SHOW_DEMO_EVENTS:
      return {
        ...state,
        demo: true,
      }
    case SHOW_NO_EVENTS:
      return {
        ...state,
        noEvents: action.payload,
      }
    case RESET_TO_LIVE_VIEW:
      return defaultState
    default:
      return state
  }
}
