import {EventsList, Tabs} from '../../../../commons/enums'
import {DetailedEvent, MemberPageState} from '../types/state'
import {getCurrentTab} from './tab'
import {isDemoMode, isNoEventsMode} from './view'

export const hasNoEvents = (state: MemberPageState): boolean => isNoEventsMode(state) || !getEventList(state).length

export const getEventList = (state: MemberPageState): DetailedEvent[] => {
  const eventsToGet = getCurrentTab(state) === Tabs.PAST ? EventsList.PAST : EventsList.UPCOMING

  return isDemoMode(state) && state.events[eventsToGet].length === 0
    ? state.demoEvents[eventsToGet]
    : state.events[eventsToGet]
}

export const getEventsTotal = (state: MemberPageState): number => {
  const eventsToGet = state.tab === Tabs.PAST ? EventsList.PAST : EventsList.UPCOMING

  return isDemoMode(state) ? state.demoEvents.total[eventsToGet] : state.events.total[eventsToGet]
}

export const hasError = (state: MemberPageState): boolean => state.events.error
