import {AnyAction} from 'redux'
import {UserConfig} from '../types/state'

const defaultState: UserConfig = {
  currentUserId: '',
  viewedSiteMemberId: '',
}

export const user = (state = defaultState, action: AnyAction): UserConfig => {
  switch (action.type) {
    default:
      return state
  }
}
