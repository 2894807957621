import {ControllerFlowAPI, IWixAPI, StructurePage} from '@wix/yoshi-flow-editor'
import * as navigation from '../../../../commons/services/navigation'
import settingsParams from '../../settingsParams'
import {MemberPageState} from '../types/state'

interface GetNoEventsPageArgs {
  wixCodeApi: IWixAPI
  state: MemberPageState
  flowAPI: ControllerFlowAPI
}

export const getNoEventsPage = async ({wixCodeApi, flowAPI}: GetNoEventsPageArgs): Promise<StructurePage> => {
  const pageId = flowAPI.settings.get(settingsParams.noUpcomingEventsLinkPageId) as string
  let page = pageId && (await navigation.getPage(wixCodeApi, pageId))
  page = page ? page : await navigation.getHomePage(wixCodeApi)
  return page
}
