export enum MembersSettingsEventsActions {
  OpenDetails = 'OPEN_DETAILS',
  OpenNoEvents = 'OPEN_NO_EVENTS',
  OpenDefault = 'OPEN_DEFAULT',
}

export enum MembersSettingsEventsKeys {
  ForceView = 'forceView',
}

export interface SettingsEvents {
  forceView: MembersSettingsEventsActions
}
