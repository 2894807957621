import {AnyAction} from 'redux'
import {SET_BASE_ENVIRONMENT, SET_FORM_FACTOR} from '../actions/environment'
import {EnvironmentConfig} from '../types/state'

const defaultState: EnvironmentConfig = {} as EnvironmentConfig

export const environment = (state = defaultState, action: AnyAction): EnvironmentConfig => {
  switch (action.type) {
    case SET_BASE_ENVIRONMENT:
      return {...state, ...action.payload}
    case SET_FORM_FACTOR:
      return {...state, mobile: action.payload}
    default:
      return state
  }
}
