import {BI_ENDPOINTS, BI_ORIGINS} from '@wix/wix-events-commons-statics'
import {AnyAction} from 'redux'
import {EventMap} from '../../../../commons/bi/interfaces'
import {Tabs} from '../../../../commons/enums'
import {SEE_OTHER_EVENTS, SET_TAB, SHARE_EVENT_CLICKED, TOGGLE_EVENT_DETAILS} from '../actions/events'
import {NAVIGATE_TO_DETAILS_PAGE} from '../actions/navigation'
import {DOWNLOAD_TICKETS_ACTION} from '../actions/tickets'
import {MemberPageState} from '../types/state'

export const eventMap: EventMap = {
  [DOWNLOAD_TICKETS_ACTION]: (_state: MemberPageState, action: AnyAction) => ({
    evid: 88,
    event_id: action.payload.eventId,
    origin: BI_ORIGINS.MEMBERS_AREA,
  }),
  [SEE_OTHER_EVENTS]: (state: MemberPageState) => ({
    evid: 156,
    action: 'see_other_events',
    tabName: getBiTabName(state.tab),
  }),
  [SET_TAB]: (_state: MemberPageState, action: AnyAction) => ({
    evid: 156,
    action: 'switch_between_tabs',
    tabName: getBiTabName(action.payload),
  }),
  [TOGGLE_EVENT_DETAILS]: (state: MemberPageState) => ({
    evid: 156,
    action: 'expand_event_details',
    tabName: getBiTabName(state.tab),
  }),
  [SHARE_EVENT_CLICKED]: (state: MemberPageState) => ({
    evid: 156,
    action: 'share_event',
    tabName: getBiTabName(state.tab),
  }),
  [NAVIGATE_TO_DETAILS_PAGE]: (state: MemberPageState) => ({
    evid: 156,
    action: 'view_event_details',
    tabName: getBiTabName(state.tab),
  }),
  endpoint: BI_ENDPOINTS.EVENTS_UOU,
}

const getBiTabName = (currentTab: Tabs) => (currentTab === Tabs.PAST ? 'past_events' : 'upcoming_events')
